import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { MARKS, INLINES } from "@contentful/rich-text-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { GatsbyImage } from "gatsby-plugin-image";
import handleSubmission from "../utils/handleSubmission";

export const query = graphql`
	query ($slug: String!) {
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
		contentfulOkrExample(slug: { eq: $slug }) {
			slug
			title
			description {
				raw
			}
			keyResults
			objective
		}
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
	}
`;

const OKRExample = (props) => {
	const example = props.data.contentfulOkrExample;
	const site = props.data.site;

	const schema = [
		{
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			itemListElement: [
				{
					"@type": "ListItem",
					position: 1,
					item: {
						"@id": `${site.siteMetadata.siteUrl}/okr-examples/`,
						name: `OKR Examples`,
					},
				},
				{
					"@type": "ListItem",
					position: 2,
					item: {
						"@id": `${site.siteMetadata.siteUrl}/okr-example/${example.slug}`,
						name: `${example.title}`,
					},
				},
			],
		},
	];

	const options = {
		renderNode: {
			"embedded-asset-block": (node) => {
				return (
					<>
						<GatsbyImage
							image={node.data.target.gatsbyImageData}
							alt={node.data.target.title}
						/>
					</>
				);
			},

			[INLINES.HYPERLINK]: (node) => {
				return (
					<a
						href={node.data.uri}
						target={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? "_self"
								: "_blank"
						}`}
						rel={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? ""
								: "noopener noreferrer"
						}`}>
						{node.content[0].value}
					</a>
				);
			},
		},
		renderMark: {
			[MARKS.CODE]: (text) => {
				return <SyntaxHighlighter style={a11yDark}>{text}</SyntaxHighlighter>;
			},
		},
	};

	const isBrowser = () => typeof window !== "undefined";
	const url = isBrowser() ? window.location.href : "";

	return (
		<Layout>
			<Head
				title={` OKR Example: ${example.title} | ${site.siteMetadata.title}`}
				description={`Want to ${example.objective.toLowerCase()}? Learn more about this and other OKR examples by clicking here.`}
				url={`${props.data.site.siteMetadata.siteUrl}/okr-examples/${example.slug}`}
				schemaMarkup={schema}
			/>

			<main className="mx-auto my-4 max-w-4xl sm:my-8 md:my-10 lg:my-12 xl:my-16">
				<div className="">
					<h1 className="text-4xl sm:text-5xl md:text-6xl flex flex-col-reverse font-bold tracking-tight text-slate-900">
						{example.objective}
						<span className="text-lg font-bold tracking-normal">
							{" "}
							Objective
						</span>
					</h1>
				</div>

				<div className="max-w-4xl mx-auto mt-4 md:mt-8 lg:mt-12">
					<div className="">
						<h2 className="text-3xl sm:text-4xl mb-2 font-bold">Key Results</h2>
						<ol className="list-decimal">
							{example.keyResults.map((edge) => (
								<li key={edge} className="ml-5 leading-7">
									{edge}
								</li>
							))}
						</ol>
					</div>

					{example.description && (
						<div className="mt-8 md:mt-12 lg:mt-20">
							<h2 className="text-3xl sm:text-4xl mb-2 font-bold">
								How to {example.objective.toLowerCase()}
							</h2>
							<div
								className="
                prose-p:text-lg prose-p:my-6 prose-blockquote:my-4
                prose-blockquote:bg-indigo-500 prose-blockquote:text-white
                prose-blockquote:mb-4 prose-blockquote:rounded
                prose-blockquote:px-4 prose-blockquote:py-1 prose-h2:mt-8
                prose-h2:mb-4 prose-h2:text-4xl prose-h2:font-bold
                prose-h2:text-slate-800 prose-h3:mb-4 prose-h3:mt-6
                prose-h3:text-3xl prose-h3:font-bold prose-h3:text-slate-900
                prose-li:list-disc prose-li:ml-3 prose-li:mt-1.5
                prose-li:text-thin prose-li:text-slate-800
                prose-a:text-indigo-600 prose-a:underline">
								{renderRichText(example.description, options)}
							</div>
						</div>
					)}
				</div>
			</main>

			<div className="max-w-4xl mx-2 lg:mx-auto px-4 py-6 sm:px-6 sm:py-8 lg:px-8 lg:py-10 my-2 sm:my-4 md:my-6 lg:my-8 xl:my-10 rounded-md bg-slate-100">
				<h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900 mb-2">
					How to achieve this OKR
				</h2>
				<p className="mb-3 leading-7">
					Most people who try OKRs fail. But there's hope. You'll need 3 key
					things to have a much better shot at achieving this and your other
					OKRs. Fill in your email and we'll take you through each of them, step
					by step.
				</p>
				<form
					className="mt-8 sm:mt-10 lg:mt-8 mx-auto flex flex-col sm:flex-row w-full"
					onSubmit={handleSubmission}
					action="/signup-complete/">
					<input type="hidden" id="name" name="name" value="contact" required />
					<input type="hidden" id="url" name="url" value={url} required />
					<input
						placeholder="name@email.com"
						type="email"
						id="email"
						name="email"
						required
						className="sm:flex items-center w-full sm:w-72 text-left space-x-3 px-4 h-12 sm:h-14 bg-white ring-1 ring-slate-900/20 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-sm rounded-lg placeholder:text-slate-400 text-slate-800"
					/>
					<button
						type="submit"
						className="bg-indigo-500 hover:bg-indigo-700 ring-1 ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-indigo-50 text-white font-semibold h-14 px-6 sm:ml-4 mt-4 sm:mt-0 rounded-lg w-full flex items-center justify-center sm:w-auto">
						Get the 3 things
					</button>
				</form>
			</div>
		</Layout>
	);
};

export default OKRExample;
